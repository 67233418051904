<template>
  <v-container id="training-plans" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="trainingPlansFiltered"
      :items-per-page="tableItemsPerPage"
      :page="currentPage"
      :search="search"
      :no-data-text="str['training_plans_empty']"
      :no-results-text="str['training_plans_empty']"
      class="elevation-1"
      @click:row="openTrainingPlan"
      @update:page="(value) => (currentPage = value)"
      @update:items-per-page="(value) => (tableItemsPerPage = value)"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-dialog v-model="dialogItem" persistent max-width="750px">
            <v-btn v-if="editPermission" color="success" dark class="mb-2" @click="newTraininPlan()">
              {{ str['add'] }}
            </v-btn>

            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['training_plan'] }}
                  {{ itemToEdit && itemToEdit.date ? '(' + itemToEdit.date + ')' : '' }}
                </span>
              </v-card-title>

              <v-col cols="12">
                <v-text-field
                  v-model="itemName"
                  :style="{
                    'pointer-events': editPermission ? '' : 'none',
                  }"
                  name="input-7-1"
                  :label="str['name']"
                  class="purple-input"
                  style="margin-top: 10px"
                  hide-details
                />
              </v-col>

              <div
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
              >
                <div v-if="editPermission" style="padding: 20px; display: flex; justify-content: center; align-items: center">
                  <v-btn color="blue" class="mr-0">
                    {{ str['add_pdf'] }}
                  </v-btn>
                  <input
                    id="input-pdf-value"
                    type="file"
                    accept="application/pdf,application/vnd.ms-excel"
                    style="opacity: 0; position: absolute; height: 45px; width: 160px"
                    @change="loadPdf"
                  />
                </div>
                <div style="padding: 0 0 20px">
                  <iframe v-if="pdfValue" :src="pdfValue" style="width: 100%; height: 50vh" />
                </div>
              </div>

              <v-card-actions>
                <v-spacer />
                <v-btn color="default" @click="closeTrainingPlan()">
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn v-if="editPermission" color="success" @click="save()">
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar v-if="showPlaceAndGender || showLevel" flat class="toolbar-adjust">
          <v-row>
            <v-col v-if="showPlaceAndGender && trngPlanPlaces && trngPlanPlaces.length" :cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.place"
                :label="str['place']"
                item-text="label"
                item-value="value"
                :items="trngPlanPlaces"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="showPlaceAndGender && trngPlanGenders && trngPlanGenders.length" :cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.gender"
                :label="str['gender']"
                item-text="label"
                item-value="value"
                :items="trngPlanGenders"
                :no-data-text="str['no_data']"
                hide-details
                style="max-width: 300px"
              />
            </v-col>
            <v-col v-if="showLevel" :cols="12" :lg="4" :md="4" :sm="12">
              <v-autocomplete
                v-model="filters.level"
                :label="str['level']"
                item-text="label"
                item-value="value"
                :items="trngPlanLevels"
                :no-data-text="str['no_data']"
                hide-details
                style="max-width: 300px"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.total="{ item }">
        {{ item.days.length }}
      </template>
      <template #item.proposal="{ item }">
        {{ item.proposal ? str['yes'] : str['no'] }}
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" color="teal lighten-2" @click.stop="duplicateItem(item)">mdi-content-duplicate</v-icon>
        <v-icon small class="mr-0" color="error" @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    const user = Utils.getUser()
    const config = user && user.configurations ? user.configurations : null
    const editPermission = Utils.hasPermission('base_trng_plans_edit')
    const headers = [
      { text: window.strings['id'], value: 'id' },
      { text: window.strings['name'], value: 'name', align: 'center' },
    ]

    if (editPermission) {
      headers.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
      })
    }

    let showPlaceAndGender = false
    if (config && config.training_plans && config.training_plans.show_place_gender) {
      showPlaceAndGender = true
    }

    let showLevel = false
    if (config && config.training_plans && config.training_plans.show_level) {
      showLevel = true
    }

    return {
      str: window.strings,
      user: user,
      trainingPlans: [],
      currentPage: 1,
      tableItemsPerPage: Utils.getTableCache('pdfTrainingPlans', 'itemsPerPage') || 10,
      search: '',
      editPermission: editPermission,
      headers: headers,
      showPlaceAndGender: showPlaceAndGender,
      showLevel: showLevel,
      filters: Utils.getTableCache('pdfTrainingPlans', 'filters') || {
        place: null,
        gender: null,
        level: null,
      },
      trngPlanPlaces: Utils.getTrngPlanPlaces(),
      trngPlanGenders: Utils.getTrngPlanGenders(),
      trngPlanLevels: Utils.getTrngPlanLevels(),
      itemName: '',
      pdfValue: '',
      dialogItem: false,
      itemToEdit: null,
      trainingPlanType: 1,
    }
  },
  computed: {
    trainingPlansFiltered() {
      const self = this
      let trainingPlans = this.trainingPlans
      if (this.showPlaceAndGender) {
        if (this.filters.place) {
          trainingPlans = trainingPlans.filter(function (item) {
            return item.place === self.filters.place
          })
        }
        if (this.filters.gender) {
          trainingPlans = trainingPlans.filter(function (item) {
            return item.gender === self.filters.gender
          })
        }
      }
      if (this.showLevel) {
        if (this.filters.level) {
          trainingPlans = trainingPlans.filter(function (item) {
            return item.level === self.filters.level
          })
        }
      }
      return trainingPlans
    },
  },
  watch: {
    dialogItem(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeTrainingPlan()
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getTrainingPlans(true)
  },
  beforeDestroy: function () {
    Utils.setTableCache('trainingPlansBase', 'itemsPerPage', this.tableItemsPerPage)
    Utils.setTableCache('trainingPlansBase', 'filters', JSON.parse(JSON.stringify(this.filters)))
    Utils.setTableCache('trainingPlansBase', 'page', this.currentPage)
  },
  methods: {
    getTrainingPlans: function (getCachePage) {
      const self = this
      Api.getTrainingPlans(
        {
          fields: ['id', 'name', 'type', 'gender', 'place', 'level'],
          fast: true,
          type: this.trainingPlanType,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.trainingPlans = response.data
            if (getCachePage) {
              self.currentPage = Utils.getTableCache('pdfTrainingPlans', 'page') || self.currentPage
            }
          } else {
            self.trainingPlans = []
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    openTrainingPlan: function (item) {
      const self = this
      this.getPlan(item, function (src) {
        self.$isLoading(false)
        self.setData(src)
        self.itemToEdit = src
        self.dialogItem = true
        self.resetPdfInput()
      })
    },
    newTraininPlan: function () {
      this.setData()
      this.itemToEdit = null
      this.dialogItem = true
      this.resetPdfInput()
    },
    closeTrainingPlan() {
      this.itemToEdit = null
      this.dialogItem = false
    },
    resetPdfInput: function () {
      setTimeout(function () {
        if (document.getElementById('input-pdf-value')) {
          document.getElementById('input-pdf-value').value = ''
        }
      })
    },
    loadPdf(e) {
      const self = this
      if (!e.target.files.length) {
        return false
      }
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        self.pdfValue = e.target.result
      }
    },
    setData: function (data) {
      if (data) {
        this.itemName = data.name
        this.pdfValue = data.details
      } else {
        this.itemName = ''
        this.pdfValue = ''
      }
    },
    save: function () {
      const self = this
      const data = this.getData()
      this.$isLoading(true)

      if (data.id) {
        Api.editTrainingPlan(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogItem = false
            self.getTrainingPlans()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      } else {
        delete data.id
        Api.newTrainingPlan(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogItem = false
            self.getTrainingPlans()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    getData: function () {
      const data = {
        name: this.itemName,
        details: this.pdfValue,
        type: this.trainingPlanType,
        base: 1,
      }
      if (this.itemToEdit && this.itemToEdit.id) {
        data.id = this.itemToEdit.id
      }
      return data
    },
    deleteItem(item) {
      const self = this
      const id = item.id
      const data = {
        id: id,
        client_id: null,
      }

      this.$confirm(
        window.strings['want_delete_training_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteTrainingPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.trainingPlans.length; i++) {
                if (self.trainingPlans[i].id === id) {
                  self.trainingPlans.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        })
        .catch(() => {})
    },
    duplicateItem(item) {
      const self = this

      this.$confirm(
        window.strings['want_duplicate_training_plan'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'info',
        Utils.getAlertOptions(true, false),
      )
        .then(() => {
          self.getPlan(item, function (src) {
            Api.newTrainingPlan(src, function (response) {
              if (response.success) {
                self.getTrainingPlans()
              } else {
                self.$isLoading(false)
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          })
        })
        .catch(() => {})
    },
    getPlan(plan, callback) {
      const self = this
      const payload = {
        id: plan.id,
      }
      this.$isLoading(true)
      Api.getTrainingPlan(payload, function (response) {
        if (response.success) {
          callback(response.data[0])
        } else {
          self.$isLoading(false)
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
  },
}
</script>
